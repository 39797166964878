<template>
<div>
<Header></Header>
     <!--顶部banner开始-->
     <div class="aboutus-banner left relative" style="margin-bottom:20px;"> <img src="~images/banner/shehuizeren.jpg" width="100%" /> </div>

     <!--内容开始-->
     <div class="social-box width-bai left clearfix">
       <div class="social-index-content clearfix center">

                <!--面包屑开始-->
                <Breadcrumb>
                <a href="/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                <a href="/#/social_responsibility" title="社会责任"> <BreadcrumbItem>社会责任</BreadcrumbItem> </a>
                <BreadcrumbItem>社会责任</BreadcrumbItem>
                </Breadcrumb>

                <div style="height:20px;"></div>

                    <!--内容开始-->
                    <a :href="'/#/social_responsibility_list?type='+item.id" v-for="item in list" :key="item.id" :title="item.title">
                    <div class="social-index-li left clearfix">
                        <!--左边开始-->
                        <div class="social-index-left left clearfix"> <img :src="item.img_path" width="700" height="380" /> </div>

                        <!--右边开始-->
                        <div class="social-index-right right clearfix">
                            <h2>{{item.title}}</h2>
                            <p>{{item.content}}</p>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>
<script>
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default {
   name:"",
   data() {
      return {
          list:''
      }
   },
   components:{
       ContactCall,
       Header,
       Footer
   },
   mounted() {
      this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_sociology.html?token='+this.$store.state.token)
			.then(res=>{
         this.list=res.data.result;
      });
   },
}
</script>

<style scoped>
.social-index-right h2,.social-index-left2 h2{line-height:50px;padding-left:20px;font-size:25px;color:#333;margin-top:20px;}
.social-index-right p,.social-index-left2 p{font-size:15px;color:#666;line-height:30px;padding:20px;padding-top:10px;}
.social-index-li img{margin:10px;}
.social-index-left,.social-index-right,.social-index-left2{width:49%;height:400px;}
.social-index-li{width:100%;height:400px;background:#efefef;border-radius:5px;margin-top:20px;margin-bottom:20px;cursor:pointer;}
.social-index-li:hover{box-shadow: 0px 2px 5px rgb(242,151,0);}
.social-index-content{width:1440px;min-height:800px;}
.social-box{min-height:800px;}
</style>
